import fetch from 'auth/FetchInterceptor';

const OrderService = {}

OrderService.FetchOrders = function (paginationParams) {
    return fetch({
        url: `/order-list?status=${paginationParams.data.status}&per_page=${paginationParams.data.per_page}&page=${paginationParams.data.page}&search=${paginationParams.data.search}&date=${paginationParams.data?.date}`,
        method: 'get'
    })
}
OrderService.FetchOrdersCounts = function () {
    return fetch({
        url: `/order-status-count`,
        method: 'get'
    })
}
OrderService.CreateOrder = function (formData) {
    return fetch({
        url: '/order-create',
        method: 'post',
        // headers: {
        //     'Content-Type': 'multipart/form-data'
        // },
        data : formData
    })
}

OrderService.UpdateOrder = function (formData) {
    return fetch({
        url: '/order-update/' + formData.formData.orderId,
        method: 'post',
        data: formData.formDatas12
    })
}
OrderService.ViewOrder = function (data) {
    return fetch({
        url: '/order-view/' + data.orderId,
        method: 'get'
    })
}
OrderService.DeleteOrder = function (orderId) {
    return fetch({
        url: '/order-delete/' + orderId.orderId,
        method: 'delete',
    })
}
OrderService.MoveDispatch = function (formData) {
    return fetch({
        url: '/move-to-dispatch',
        method: 'post',
        data: formData
    })
}
OrderService.CustomerListOrder = function (data) {
    return fetch({
        url: '/customer-view/' + data.customerId,
        method: 'get'
    })
}
OrderService.PostOrder = function (formData) {
    return fetch({
        url: '/post-related-bills',
        method: 'post',
        // headers: {
        //     'Content-Type': 'multipart/form-data'
        // },
        data : formData
    })
}

export default OrderService

